<template>
  <div class="bannerContent">
    <Header :title="postersData[cId].title" theme="light" />
    <div class="container">
      <div v-if="postersData[cId].details != ''">
        <div v-html="postersData[cId].details">
        </div>
      </div>
      <div v-else>
        <iframe style="height: 100vh" v-bind:href="postersData[cId].linkPath" :src="postersData[cId].linkPath" ref="iframe"></iframe>
        </div>
    </div>
    
  </div>
</template>
<script>
import Header from "@/components/Header.vue";

import cookie from "js-cookie";
import { mapActions, mapState } from "vuex";
export default {
  name: "bannerContent",
  components: {
    Header
    
  },
  data() {
    return {
      cId:this.$route.params.cId,
      newPageVal:[],
        show: false,
        styleObject:{
            overflow: 'hidden',
            height : '100%',
            width : '100%',
        },
        iframeWindow:{},
    };
  },
  computed: {
    ...mapState("posters", ["postersData"]),
  },
  mounted() {
    this.iframeWindow = this.$refs.iframe.contentWindow
    window.addEventListener('message',(e)=>{
      if(e.data.msg==='window location reload'){
        this.load()
        this.resetHeight()
      }
    });    
  },
  created() {
    this.getPosters();
    this.show_item_page(this.postersData,this.cId);
    this.resetHeight();
  },
  watch: {
  
  },
  methods: {
    ...mapActions("posters", ["getPosters"]),
    show_item_page(item,i) {
        this.newPageVal = item[i];
        this.show = true;      
        
        this.load()    
    },
    closePage () {
        this.show = false;
        //關閉時陣列設為空
        this.newPageVal = [];
    },
    resetHeight(){
        let iframe = this.$refs.iframe || undefined;
        if(iframe === undefined) return
        iframe.style.height ='0';
        setTimeout(() => {
           iframe.style.height='100%';    
        }, 500);
    },
    load(){
        let data = {
            code : 'success',
            token : cookie.get("token") || '',
            sign : cookie.get("sign") || '',
            timestamp : cookie.get("timestamp") || '',
        }
        setTimeout(()=>{
            if(this.iframeWindow && this.newPageVal.linkPath !==''){
              this.$refs.iframe.contentWindow.postMessage(data,this.newPageVal.linkPath)
            }
        },1000)
    }
  },
};
</script>
<style lang="scss" scoped>
.bannerContent{
  width: 100%;
  .container{
    height: calc(100vh - 52px);
    overflow-y: scroll;
  }
}
</style>
